<template>
  <section>
    <div v-for="item in logsData" :key="item.id" class="activityLog-item padding-0">
      <p>
        <strong>{{ item.broker ? item.broker.name : "System" }}</strong>
        {{ item.prettyText }}
      </p>
      <tooltip class="activityLog-date" :content="$customFilters.datetime(item.created_at)" placement="left">
        <span>{{ $customFilters.calendar(item.created_at) }}</span>
      </tooltip>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue"
import { Broker } from "@/interfaces/db"
import { formatLog } from "../config/logs"
import useCore from "@/plugins/use-core"

const { axios } = useCore()

const props = defineProps<{
  resourceType: string
  resourceId: number
  brokers: Broker[]
}>()

const logsData = ref([])
const loading = ref(false)

const fetchLogs = () => {
  loading.value = true
  axios
    .get(`/api/v1/activity_logs?loggable_id=${props.resourceId}&loggable_type=${props.resourceType}`)
    .then(({ data }) => {
      logsData.value = data.data.map(b => formatLog(b, props.brokers))
      loading.value = false
    })
}

// is this function still relevant/needed?
const subscribeToLogs = () => {
  channel.bind(`log:${props.resourceType}:${props.resourceId}:created`, log => {
    logsData.value = logsData.value.concat([formatLog(log)])
  })
}

watch(
  () => props.resourceId,
  () => fetchLogs()
)

onMounted(() => fetchLogs())
</script>
