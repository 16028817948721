<template>
  <div class="app-panel">
    <nice-page-header>
      <template v-slot:leftMenu>
        <a v-if="taskId" href="#" @click.prevent="back" class="pane-button">
          <fa-icon name="long-arrow-left" />
        </a>
        <a v-else href="#" @click.prevent="close" class="pane-button" data-tagid="taskFormClose">
          <fa-icon name="times" />
        </a>
      </template>
      <template v-slot:rightButtons>
        <p v-if="source?.dealIds" class="text-primary mr-4">
          <strong>{{ source.dealIds.length }}</strong>
          {{ $t("panes.taskForm.totalDeals") }}
        </p>
        <tooltip
          v-if="!isEvent"
          :content="rightsVisible ? $t('panes.taskForm.accessRightsOff') : $t('panes.taskForm.accessRightsOn')"
        >
          <a
            href="#"
            @click.prevent="rightsVisible = !rightsVisible"
            class="mr-3 rights-btn standard-hover"
            :class="{ active: rightsVisible }"
          >
            <fa-icon v-if="rightsVisible" name="lock" />
            <fa-icon v-else name="unlock" />
          </a>
        </tooltip>
        <a
          href="#"
          data-toggle="error-popover"
          @click.prevent="submit"
          class="ml-2 pane-button"
          :class="{ disabled: submitting }"
          data-tagid="taskFormSave"
        >
          <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
          {{ $t("panes.taskForm.save") }}
        </a>
      </template>
    </nice-page-header>
    <spinner v-if="loading" />
    <div v-else class="app-panel-body">
      <form @submit.prevent="submit" class="task-inner">
        <div class="mb-3">
          <nice-textarea
            v-model="params.title"
            :placeholder="$t('panes.taskForm.title')"
            @update:modelValue="dirty = !!$event"
            :minRows="1"
            resize="none"
            class="text-lg font-medium"
          />
        </div>

        <template v-if="isEvent">
          <form-row :title="$t('panes.taskForm.startsAt')">
            <div class="relative w-100">
              <nice-date-picker
                v-model="params.startsAt"
                :type="params.allDay ? 'date' : 'datetime'"
                :format="eventFormat"
                class="w-100"
                :class="{ 'datepicker--taken': selectedTimeAvailable == false }"
                :placeholder="$t('panes.taskForm.startsAtPh')"
                :clearable="false"
                @update:modelValue="setEndsAt"
                :auto-apply="false"
                @change="setEndsAt"
              />
              <tooltip
                :content="
                  selectedTimeAvailable
                    ? $t('panes.taskForm.timeAvailability.available', { user: timeAvailabilityUsername })
                    : $t('panes.taskForm.timeAvailability.taken', { user: timeAvailabilityUsername })
                "
                v-if="selectedTimeAvailable == false"
                class="absolute right-2"
                style="top: 8px"
              >
                <fa-icon v-if="!selectedTimeAvailable" name="times fa-fw" class="text-red-600" />
                <!-- <fa-icon v-if="selectedTimeAvailable" name="check fa-fw" class="text-green-600" /> -->
              </tooltip>
            </div>
          </form-row>
          <form-row :title="$t('panes.taskForm.endsAt')">
            <nice-date-picker
              v-model="params.endsAt"
              :type="params.allDay ? 'date' : 'datetime'"
              :format="eventFormat"
              class="w-100"
              :class="{ 'datepicker--taken': selectedTimeAvailable == false }"
              :placeholder="$t('panes.taskForm.endsAtPh')"
              :clearable="false"
              @update:modelValue="calcDifferenceInMinutes"
            />
          </form-row>
          <form-row :title="$t('panes.taskForm.buffer')" :hint="$t('panes.taskForm.bufferHint')" v-if="!params.allDay">
            <nice-select
              v-model="params.bufferBefore"
              :options="bufferDurations"
              :placeholder="$t('panes.taskForm.bufferBefore')"
              class="mr-1"
            />
            <nice-select
              v-model="params.bufferAfter"
              :options="bufferDurations"
              :placeholder="$t('panes.taskForm.bufferAfter')"
            />
          </form-row>
          <form-row :title="$t('panes.taskForm.allDay')">
            <div class="flex justify-between w-100">
              <div>
                <nice-checkbox v-model="params.allDay" />
              </div>
              <div>
                <nice-checkbox v-model="recurring">
                  <span class="text-gray-600 text-sm">{{ $t("panes.taskForm.recurring") }}</span>
                </nice-checkbox>
              </div>
            </div>
          </form-row>
          <div class="mb-4 bg-gray-100 border rounded p-4" v-if="recurring">
            <p class="mb-4">
              <strong>{{ $t("panes.taskForm.userDefinedLoop") }}</strong>
            </p>
            <div class="form-group flex items-center">
              <p class="mr-3" style="white-space: nowrap">{{ $t("panes.taskForm.repeatAll") }}</p>
              <div class="mr-3">
                <nice-input size="small" v-model="crrule.interval" />
              </div>
              <div class="grow">
                <nice-select v-model="crrule.freq" :options="rruleFreqOptions" />
              </div>
            </div>
            <div key="byweek-fields" class="form-group" v-if="crruleFreqIsWeekly">
              <nice-select v-model="crrule.byweekday" :multiple="true" :options="weekdays" />
            </div>
            <div key="bymonth-fields" class="form-group" v-else-if="crruleFreqIsMonthly">
              <nice-select v-model="crrule.bymonth" :options="crruleByMonthOptions" />
            </div>
            <div class="form-group">
              <p class="mb-3">{{ $t("panes.taskForm.end") }}</p>
              <div class="mb-3 align-items-center">
                <div class="mt-2 mr-3" style="width: 100px">
                  <span class="radio radio-primary mb-0">
                    <input
                      type="radio"
                      name="crrule_end_type"
                      id="crrule_end_type_0"
                      value="never"
                      v-model="crrule.endtype"
                    />
                    <label for="crrule_end_type_0">{{ $t("panes.taskForm.never") }}</label>
                  </span>
                </div>
              </div>
              <div class="form-group d-flex align-items-center">
                <div class="mt-2 mr-3" style="width: 100px">
                  <span class="radio radio-primary mb-0">
                    <input
                      type="radio"
                      name="crrule_end_type"
                      id="crrule_end_type_1"
                      value="date"
                      v-model="crrule.endtype"
                    />
                    <label for="crrule_end_type_1">{{ $t("panes.taskForm.date") }}</label>
                  </span>
                </div>
                <div>
                  <nice-date-picker
                    v-model="crrule.until"
                    type="date"
                    format="dd.MM.yyyy"
                    class="w-100"
                    :clearable="false"
                    :readonly="isNotCrruleEndtypeDate"
                  />
                </div>
              </div>
              <div class="form-group d-flex align-items-center">
                <div class="mt-2 mr-3" style="width: 100px">
                  <span class="radio radio-primary mb-0">
                    <input
                      type="radio"
                      name="crrule_end_type"
                      id="crrule_end_type_2"
                      value="days"
                      v-model="crrule.endtype"
                    />
                    <label for="crrule_end_type_2">{{ $t("panes.taskForm.days") }}</label>
                  </span>
                </div>
                <div>
                  <nice-input size="small" type="number" v-model="crrule.count" :disabled="isNotCrruleEndtypeDays" />
                </div>
                <p class="ml-3">{{ $t("panes.taskForm.events") }}</p>
              </div>
            </div>
          </div>
        </template>

        <form-row v-if="type == 'reason' || params.cancelationReasonId" :title="$t('panes.taskForm.cancellation')">
          <db-select
            v-model="params.cancelationReasonId"
            collection="cancelationReasons"
            :clearable="false"
            @change="cancellationReasonChanged"
          />
        </form-row>
        <form-row v-else-if="type == 'inquiry' || params.clientSourceId" :title="$t('panes.taskForm.source')">
          <db-select v-model="params.clientSourceId" collection="clientSources" :clearable="false" />
        </form-row>
        <form-row v-else :title="$t('panes.taskForm.category')" data-tagid="taskFormCategory">
          <db-select
            v-model="params.categoryId"
            :collection="categoryCollectionName"
            @change="categoryChanged"
            :filter-func="item => !item.internal"
          >
            <template v-slot:option="{ item }">
              <div class="flex justify-between items-center">
                {{ item.name }}
                <bubble
                  v-if="item.hideInReports"
                  type="danger"
                  :tooltipText="$t('activityTypes.hideInReports')"
                  class="relative mr-2"
                >
                  <fa-icon name="user-chart" class="text-gray-800" />
                  <fa-icon name="slash" class="absolute" />
                </bubble>
              </div>
            </template>
          </db-select>
        </form-row>

        <form-row :title="$t('panes.taskForm.taskStageId')" v-if="selectedPipelineId">
          <db-select
            v-model="params.taskStageId"
            collection="taskStages"
            :filter-func="b => selectedPipelineId == b.dealPipelineId"
          />
        </form-row>

        <form-row :title="$t('panes.taskForm.assignedTo')" data-tagid="taskFormAssignedTo">
          <broker-department-select
            v-model:brokerId="params.brokerId"
            v-model:departmentId="params.departmentId"
            @change="assignedChanged"
          />
        </form-row>

        <template v-if="!source || !source.dealIds">
          <form-row :title="$t('panes.taskForm.contacts')" data-tagid="taskFormContacts">
            <p v-if="massClientIds && massClientIds.length" class="text-primary">
              <strong>{{ massClientIds.length }}</strong>
              {{ $t("panes.taskForm.selected") }}
            </p>
            <client-select
              v-else
              v-model="params.clientIds"
              multiple
              @change="setAutoParams"
              @ready="setSelectedClients"
            />
          </form-row>

          <form-row :title="$t('panes.taskForm.properties')" data-tagid="taskFormProperties">
            <p v-if="massPropertyIds && massPropertyIds.length" class="text-primary">
              <strong>{{ massPropertyIds.length }}</strong>
              {{ $t("panes.taskForm.selectedProperties") }}
            </p>
            <property-select
              v-else
              v-model="params.propertyIds"
              multiple
              variants
              @change="propertyChanged"
              @change-project="params.projectIds = $event"
              @ready="setSelectedProperties"
            />
          </form-row>

          <form-row :title="$t('panes.taskForm.projects')">
            <project-select v-model="params.projectIds" multiple @change="projectChanged" />
          </form-row>
        </template>

        <form-row :title="$t('panes.taskForm.reviewers')" v-if="isReminder && $db.extensionEnabled('task_review')">
          <db-select
            multiple
            v-model="params.reviewerIds"
            collection="activeBrokers"
            :filter-func="b => params.brokerId !== b.id"
          />
        </form-row>

        <form-row :title="$t('panes.taskForm.subscribers')" :hint="$t('panes.taskForm.subscribersHint')">
          <db-select
            multiple
            v-model="params.brokerIds"
            collection="activeBrokers"
            :placeholder="$t('panes.taskForm.selectMore')"
          />
        </form-row>

        <form-row v-if="isNote" :title="$t('panes.taskForm.activityDate')">
          <nice-date-picker
            v-model="params.startsAt"
            type="datetime"
            format="dd.MM.yyyy HH:mm"
            class="w-100"
            :placeholder="$t('panes.taskForm.activityDatePh')"
            :clearable="false"
          />
        </form-row>

        <template v-if="isReminder || isDecision">
          <form-row :title="$t('panes.taskForm.dueDate')">
            <nice-date-picker
              :model-value="params.dueDate"
              type="datetime"
              format="dd.MM.yyyy HH:mm"
              class="w-100"
              :placeholder="$t('panes.taskForm.dueDatePh')"
              @update:modelValue="dueDateChanged"
            />
          </form-row>
          <form-row :title="$t('panes.taskForm.priorityTitle')">
            <nice-select v-model="params.priority" :options="priorityOptions" :clearable="false">
              <template v-slot:option="{ item }">
                <fa-icon
                  name="square"
                  class="mr-2 text-black"
                  :class="{ 'text-red-500': item.id == 'high', 'text-blue-500': item.id == 'low' }"
                />
                {{ item.name }}
              </template>
            </nice-select>
          </form-row>
        </template>

        <template v-if="isEvent">
          <form-row :title="$t('panes.taskForm.location')">
            <div class="w-100">
              <tag-select
                v-model="params.location"
                :options="locations"
                allow-create
                :multiple="false"
                :placeholder="$t('panes.taskForm.locationPh')"
              />
              <p class="text-yellow-700 mt-2 -mb-1 text-xs" v-if="locationHasVariables">
                {{ $t("panes.taskForm.locationVariables") }}
              </p>
            </div>
          </form-row>
          <form-row :title="$t('panes.taskForm.private')">
            <nice-switch v-model="params.private" />
          </form-row>
          <form-row :title="$t('panes.taskForm.team')">
            <nice-switch v-model="params.isOnlineMeeting" />
          </form-row>
          <form-row :title="$t('panes.taskForm.guests')">
            <email-select multiple v-model="params.participantEmails" :placeholder="$t('panes.taskForm.guestsPh')" />
          </form-row>
          <form-row :title="$t('panes.taskForm.resources')">
            <db-select v-model="params.roomResourceEmails" collection="roomResources" multiple />
          </form-row>
          <form-row :title="$t('panes.taskForm.massEvents')">
            <nice-switch v-model="params.massViewing" />
          </form-row>
          <section class="taskForm-massViewingFields" v-show="params.massViewing">
            <form-row :title="$t('panes.taskForm.slotStep')">
              <nice-select v-model="params.slotStep" :options="slotSteps" />
            </form-row>
            <form-row :title="$t('panes.taskForm.participants')" :hint="$t('panes.taskForm.participantsHint')">
              <nice-input size="small" type="number" v-model="params.maxParticipantsPerSlot" placeholder="1" />
            </form-row>
            <form-row
              :title="$t('panes.taskForm.snippet')"
              :hint="`${$t(
                'panes.taskForm.snippetHint'
              )} <a target='blank' href='https://propstack.zendesk.com/hc/de/articles/18385951788317-Massentermine' class='underline'>${$t(
                'panes.taskForm.snippetHintLink'
              )}</a>`"
            >
              <snippet-select v-model="params.viewingReplySnippetId" />
            </form-row>
            <form-row
              :title="$t('panes.taskForm.cancellationSnippet')"
              :hint="$t('panes.taskForm.cancellationSnippetHint')"
            >
              <snippet-select v-model="params.viewingCancellationSnippetId" />
            </form-row>
          </section>
        </template>

        <form-row v-if="isReminder || isEvent || isDecision" :title="$t('panes.taskForm.reminder')">
          <nice-select v-model="params.remindAtInMinutes" :options="remindOptions" :clearable="false" />
        </form-row>
        <form-row v-if="isEvent" :title="$t('panes.taskForm.clientReminder')">
          <nice-checkbox v-model="params.sendReminders" class="flex items-center">
            <small>
              {{ $db.shopData.canSendSmsReminders ? $t("panes.taskForm.canSeeSms") : "" }}
              {{ $t("panes.taskForm.sendReminder") }}
            </small>
          </nice-checkbox>
        </form-row>
        <nice-alert v-if="params.sendReminders && within48h" type="warning" class="mb-2">
          {{ $t("panes.taskForm.warning") }}
          {{ $db.shopData.canSendSmsReminders ? $t("panes.taskForm.warningCanSeeSms") : undefined }}
        </nice-alert>

        <form-row :title="$t('panes.taskForm.tags')">
          <db-select v-model="params.groupIds" collection="formattedActivityGroups" label="activityGroups" multiple />
        </form-row>

        <form-row
          v-if="
            ((params.accessBrokerIds && params.accessBrokerIds.length) ||
              (params.accessDepartmentIds && params.accessDepartmentIds.length) ||
              rightsVisible) &&
            !isEvent
          "
          :title="$t('panes.taskDetail.userRights')"
        >
          <db-select collection="activeBrokers" multiple v-model="params.accessBrokerIds" />
        </form-row>
        <form-row
          v-if="
            ((params.accessBrokerIds && params.accessBrokerIds.length) ||
              (params.accessDepartmentIds && params.accessDepartmentIds.length) ||
              rightsVisible) &&
            !isEvent
          "
          :title="$t('panes.taskDetail.departmentRights')"
        >
          <db-select collection="departments" multiple v-model="params.accessDepartmentIds" />
        </form-row>

        <template v-if="hasCustomFields">
          <form-row v-for="(field, idx) in customFields" :key="idx">
            <base-field :key="field.fieldName" :field="field" :record="params" />
          </form-row>
        </template>

        <div class="mb-2">
          <rich-textarea ref="body" v-model="params.body" />
        </div>
        <form-row :title="$t('panes.taskForm.attachments')">
          <div class="w-full">
            <upload-area
              :action="`/api/v1/note_attachments`"
              @success="uploaded($event)"
              width="100%"
              :title="$t('panes.taskForm.upload')"
              accept=""
              multiple
            />
            <card v-for="ma in params.noteAttachments" :key="ma.id" class="mt-2">
              <div class="px-2 py-2 flex justify-between items-center">
                <div class="w-full mr-1">
                  <fa-icon name="paperclip" class="mr-1 text-blue-800" />
                  {{ ma.name }}
                  <a :href="ma.url" class="ml-2 text-gray-600" target="_blank">
                    <fa-icon name="external-link" />
                  </a>
                </div>
                <aside>
                  <a href="#" @click.prevent="removeAttachment(ma)" class="text-red-600 px-2">
                    <fa-icon name="times" />
                  </a>
                </aside>
              </div>
            </card>
          </div>
        </form-row>
      </form>
    </div>
  </div>
</template>

<script>
import { render } from "micromustache"
import EmailSelect from "@/components/message/EmailSelect"
import { WEEKDAYS, weekOfMonth, getCRRule, parseCRRule } from "@/utils/crrule"
import eventBus from "@/config/event-bus"
import { cachedClients, cachedProperties } from "@/config/remote-data-cache"
import detailViewForm from "@/config/detail-view-form"
import SaveStateMixin from "@/mixins/save-state-mixin"
import * as _ from "lodash"
import moment from "moment-timezone"

const resourceFields = `title taskStageId clientSourceId cancelationReasonId categoryId brokerId body brokerIds reviewerIds
  clientIds propertyIds projectIds
  groupIds noteAttachments { id name url }
  priority dueDate remindAtInMinutes createdAt
  startsAt endsAt allDay rrule private sendReminders
  roomResourceEmails isOnlineMeeting participantEmails
  massViewing slotStep maxParticipantsPerSlot viewingReplySnippetId
  rrule location customFields bufferBefore bufferAfter previousActivityId previousActivityType
  accessBrokerIds accessDepartmentIds departmentId viewingCancellationSnippetId
`

const deserializeCustomFields = params => ({
  ..._.omit(params, ["customFields"]),
  ..._.mapKeys(params.customFields, (_, key) => `cf_${key}`),
})

const serializeCustomFields = params => ({
  ..._.pickBy(params, (_, key) => !key.startsWith("cf_")),
  customFields: _.mapKeys(
    _.pickBy(params, (_, key) => key.startsWith("cf_")),
    (_, key) => key.substr(3)
  ),
})

export default {
  components: { EmailSelect },
  props: ["type", "source", "massAction", "followupMessageId", "followupTaskId", "id", "searchFilterId", "date"],
  mixins: [SaveStateMixin],
  data() {
    return {
      taskId: this.id,
      loading: true,
      dirty: false,
      submitting: false,
      differenceInMinutes: 30,
      recurring: false,
      rightsVisible: false,
      crrule: {
        count: undefined,
        until: undefined,
        intervall: undefined,
        freq: undefined,
        byweekday: undefined,
        bymonth: undefined,
        endtype: undefined,
      },
      params: {
        title: "",
        taskStageId: null,
        clientSourceId: null,
        cancelationReasonId: null,
        categoryId: null,
        brokerId: this.$db.broker.id,
        departmentId: null,
        clientIds: [],
        propertyIds: [],
        projectIds: [],
        reviewerIds: [],
        brokerIds: [],
        groupIds: [],
        noteAttachments: [],
        location: null,
        body: null,
        rrule: null,
        accessBrokerIds: [],
        accessDepartmentIds: [],
      },
      selectedClients: [],
      selectedProperties: [],
      checkingAvailability: false,
      selectedTimeAvailable: null,
    }
  },
  watch: {
    recurring() {
      this.updateRRule()
    },
    crrule: {
      handler() {
        this.updateRRule()
      },
      deep: true,
    },
    "params.startsAt": function () {
      this.updateRRule()
    },
    "params.endsAt": function () {
      this.checkAvailability()
    },
    "params.allDay": function () {
      this.checkAvailability()
    },
    "params.brokerId": {
      handler() {
        this.checkAvailability()
      },
      deep: true,
    },
    "params.clientIds": {
      handler() {
        this.setSelectedClients()
      },
      deep: true,
    },
    "params.propertyIds": {
      handler() {
        this.setSelectedProperties()
      },
      deep: true,
    },
  },
  methods: {
    cancellationReasonChanged(cancelationReasonId) {
      if (this.dirty) return

      const cancelationReasonName = this.$db.shopData.cancelationReasons.find(
        ({ id }) => id === cancelationReasonId
      )?.name
      this.params.title = cancelationReasonName
    },
    setSelectedClients() {
      this.selectedClients = cachedClients.filter(p => this.params.clientIds.includes(p.id))
    },
    setSelectedProperties() {
      this.selectedProperties = cachedProperties.filter(p => this.params.propertyIds.includes(p.id))
    },
    updateRRule() {
      this.params.rrule = this.recurring ? getCRRule(this.params.startsAt, this.crrule) : undefined
    },
    submit() {
      if (!this.validate()) return
      this.submitting = true
      const responseData = this.taskId ? "task { id }" : "task { id } followupResourceType"

      this.$api
        .mutation(this.taskId ? "updateTask" : "createTask", this.getPayload(), responseData)
        .then(({ task, followupResourceType }) => {
          App.flashy(this.$t("panes.taskForm.createSuccess"))

          if (followupResourceType) {
            eventBus.$emit("quick-view", {
              type: followupResourceType,
              mode: "edit",
              params: followupResourceType == "message" ? { source: { taskId: task.id } } : { followupTaskId: task.id },
            })
          } else {
            if (this.massClientIds) eventBus.$emit("quick-view-submit")
            this.$emit("close")
            if (this.type == "todo" || this.type == "reminder") {
              eventBus.$emit("quick-view", {
                type: "todo",
                mode: "view",
                id: task.id,
              })
            }
          }
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.submitting = false
        })
    },
    getPayload() {
      let payload = {
        ...serializeCustomFields(this.params),
        bufferBefore: (!this.params.allDay && this.params.bufferBefore) || null,
        bufferAfter: (!this.params.allDay && this.params.bufferAfter) || null,
        startsAt: this.params.allDay ? moment(this.params.startsAt).set({ h: 0, m: 0 }).toDate() : this.params.startsAt,
        endsAt: this.params.allDay ? moment(this.params.endsAt).set({ h: 0, m: 0 }).toDate() : this.params.endsAt,
        isReminder: this.isReminder,
        isEvent: this.isEvent,
        noteAttachmentIds: this.params.noteAttachments.map(o => o.id),
        noteAttachments: undefined,
        createdAt: undefined,
        rrule: this.params.rrule,
        accessBrokerIds: this.params.accessBrokerIds,
        accessDepartmentIds: this.params.accessDepartmentIds,
        dealIds: this.source?.dealIds,
      }
      if (this.type === "decision") {
        payload = { ...payload, taskType: this.type }
      }
      if (this.taskId) {
        return { payload, id: this.taskId }
      } else {
        return {
          payload,
          massClientIds: (Array.isArray(this.massClientIds) && this.massClientIds) || undefined,
          massClientFilter: (!Array.isArray(this.massClientIds) && this.massClientIds) || undefined,
          searchFilterId: this.searchFilterId,
        }
      }
    },
    close() {
      this.$emit("close")
    },
    back() {
      this.$emit("params", {
        type: this.type,
        mode: "view",
        id: this.taskId,
        postfix: this.date,
        params: { date: this.date },
      })
    },
    setAutoParams() {
      setTimeout(_ => {
        const newParams = this.getAutoParams()
        if (newParams && newParams.title) this.params.title = newParams.title
        if (newParams && newParams.body) this.$refs.body.setValue(newParams.body)
      }, 100)
    },
    setTaskStage() {
      if (this.selectedPipelineId) {
        this.params.taskStageId = this.$db.shopData.taskStages.find(
          o => o.dealPipelineId == this.selectedPipelineId
        )?.id
      } else {
        this.params.taskStageId = null
      }
    },
    setRoomResources() {
      const roomResourceEmails = this.noteType && this.noteType.roomResourceEmails

      if (roomResourceEmails && roomResourceEmails.length) this.params.roomResourceEmails = roomResourceEmails
    },
    getAutoParams() {
      if (this.dirty) return

      if (this.noteType && this.noteType.titleFormula) {
        const formulaOptions = {
          kontakt_name: this.selectedClients.map(o => o.name).join(", "),
          kontakt_firma: this.selectedClients.map(o => o.company).join(", "),
          kontakt_email: this.selectedClients.map(o => o.email).join(", "),
          kontakt_telefon: this.selectedClients.map(o => o.phone).join(", "),
          objekt_name: this.selectedProperties.map(o => o.name).join(", "),
          objekt_nr: this.selectedProperties.map(o => o.unit_id).join(", "),
          objekt_strasse: this.selectedProperties.map(o => o.street).join(", "),
          objekt_hausnummer: this.selectedProperties.map(o => o.house_number).join(", "),
          objekt_ort: this.selectedProperties.map(o => o.city).join(", "),
          objekt_kategorie:
            this.$db.shopData.propertyOptionsMapping.marketing_type[
              this.selectedProperties.map(o => o.marketing_type)[0]
            ],
          projekt_name: this.selectedProjects.map(o => o.name).join(", "),
        }

        return { title: render(this.noteType.titleFormula, formulaOptions), body: this.noteTypeBody }
      }

      return { title: this.noteTypeTitle, body: this.noteTypeBody }
    },
    async fetchResource() {
      try {
        const { task } = await this.$graphql(
          `query editTask {
        task(id: $id, date: $date) { ${resourceFields} }
      }`,
          { id: this.taskId, date: this.date }
        )
        this.params = task || this.params
        this.params = deserializeCustomFields(this.params)
        this.crrule = parseCRRule(task.rrule)
        if (task.rrule) {
          this.recurring = true
        } else {
          this.recurring = false
        }
        this.calcDifferenceInMinutes()
        setTimeout(_ => {
          if (this.getAutoParams().title != task.title) {
            this.dirty = true
          }
        }, 1000)
        this.loading = false
      } catch (e) {
        this.close()
      }
    },
    async buildResource() {
      const { task } = await this.$api.mutation(
        "buildTask",
        { taskType: this.type, followupTaskId: this.followupTaskId, messageId: this.followupMessageId },
        `task { ${resourceFields} }`
      )
      if (task.startsAt) {
        task.startsAt = moment.tz(task.startsAt.substr(0, 19), moment.tz.guess() || "Europe/Berlin")
      }
      if (task.endsAt) {
        task.endsAt = moment.tz(task.endsAt.substr(0, 19), moment.tz.guess() || "Europe/Berlin")
      }
      this.params = task
      this.params.dueDate = task.dueDate || new Date()
      this.crrule = parseCRRule(task.rrule)
      this.calcDifferenceInMinutes()

      if (this.source) {
        this.params = { ...this.params, ..._.pickBy(this.source, val => !!val) }

        if (this.source.categoryId) this.categoryChanged()
        if (this.source.startsAt && !this.source.endsAt) this.setEndsAt()
      }
      this.setTaskStage()
      this.loading = false
    },
    uploaded(attachment) {
      this.params.noteAttachments.push(attachment)
      App.flashy(this.$t("panes.taskForm.uploadSuccess"))
    },
    removeAttachment(attachment) {
      this.params.noteAttachments = this.params.noteAttachments.filter(o => o.id != attachment.id)
    },
    calcDifferenceInMinutes() {
      if (this.params.startsAt && this.params.endsAt) {
        this.differenceInMinutes = moment(this.params.endsAt).diff(this.params.startsAt, "minutes")
      } else {
        this.differenceInMinutes = 30
      }
    },
    setEndsAt() {
      if (!this.params.startsAt) return

      this.params.endsAt = moment(this.params.startsAt).add(this.differenceInMinutes, "minutes").toDate()
    },
    categoryChanged() {
      this.setTaskStage()
      this.setRoomResources()
      this.setAutoParams()
      this.setPropertyViewingLocation()
      this.setReviewerIds()
      this.setAssignedTo()
    },
    propertyChanged() {
      this.setAutoParams()
      this.setPropertyViewingLocation()
    },
    projectChanged(event) {
      this.params.projectIds = event
      this.setAutoParams()
      this.setPropertyViewingLocation()
    },
    dueDateChanged(newValue) {
      const oldValue = this.params.dueDate

      if (!newValue) {
        this.params.dueDate = null
        return
      }

      const newDate = moment(newValue)

      if (newDate.isSame(moment(), "date") || (oldValue && moment(oldValue).isSame(newDate, "date"))) {
        this.params.dueDate = newValue
      } else {
        this.params.dueDate = newDate.set({ h: 9, m: 0 }).toDate()
      }
    },
    assignedChanged() {
      if (!this.params.brokerIds?.length && this.params.brokerId != this.$db.broker.id)
        this.params.brokerIds = [this.$db.broker.id]
    },
    setPropertyViewingLocation() {
      if (this.isEvent && this.noteTypeTitle && this.noteTypeTitle.match(/(besichtigung|bewertung)/i)) {
        this.$nextTick(() => {
          const address = this.selectedProperties.length
            ? this.selectedProperties.map(o => o.short_address)[0]
            : this.selectedProjects.length
            ? this.selectedProjects.map(o => o.address)[0]
            : null
          if (address) this.params.location = address
        })
      }
    },
    validate() {
      if (this.type === "reason" && !this.params.cancelationReasonId) {
        this.$alert(this.$t("panes.taskForm.invalid"))
        return false
      }
      return true
    },
    setReviewerIds() {
      if (this.isReminder) {
        this.params.reviewerIds = this.noteType?.reviewerIds
      }
    },
    setAssignedTo() {
      if (this.isReminder) {
        if (this.noteType?.brokerId) {
          this.params.departmentId = null
          this.params.brokerId = this.noteType?.brokerId
        }

        if (this.noteType?.departmentId) {
          this.params.brokerId = null
          this.params.departmentId = this.noteType?.departmentId
        }
      }
    },
    checkAvailability() {
      if (!this.params.startsAt || !this.params.endsAt || this.params.allDay) {
        return (this.selectedTimeAvailable = null)
      }

      this.checkingAvailability = true
      this.$api
        .mutation(
          "checkCalendarAvailability",
          {
            taskId: this.taskId,
            startsAt: this.params.startsAt,
            endsAt: this.params.endsAt,
            brokerId: this.params.brokerId,
            departmentId: this.params.departmentId,
          },
          "available"
        )
        .then(({ available }) => {
          this.selectedTimeAvailable = available
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.checkingAvailability = false
        })
    },
  },
  computed: {
    saveStateConfig() {
      return {
        cacheKey: "task-form",
        saveProperties: ["rightsVisible"],
      }
    },
    bufferDurations() {
      return [
        { id: 5 },
        { id: 10 },
        { id: 15 },
        { id: 20 },
        { id: 30 },
        { id: 45 },
        { id: 60 },
        { id: 90 },
        { id: 120 },
      ].map(d => ({ ...d, name: this.$t("panes.taskForm.minutes", { time: d.id }) }))
    },
    taskDetailViewGroups() {
      return (this.$db.shopData.taskDetailViewGroups || []).reduce((agg, cur) => {
        if (
          cur.taskTypes.includes(this.type) &&
          (!cur.taskCategoryIds || !cur.taskCategoryIds.length || cur.taskCategoryIds.includes(this.params.categoryId))
        ) {
          agg.push({
            ...cur,
            detailViewFields: cur.detailViewFields.filter(
              e =>
                e.taskTypes.includes(this.type) &&
                (!e.taskCategoryIds || !e.taskCategoryIds.length || e.taskCategoryIds.includes(this.params.categoryId))
            ),
          })
        }
        return agg
      }, [])
    },
    hasCustomFields() {
      return this.taskDetailViewGroups.some(t => t.detailViewFields.length)
    },
    customFields() {
      return detailViewForm(
        {},
        this.taskDetailViewGroups,
        false,
        this.$db,
        this.$db.shopData.customFieldGroupsForTasks,
        field => ({ placeholder: field.formField.label }),
        "task"
      )
        .map(g => g.fields)
        .flat()
    },
    massClientIds() {
      return this.massAction ? this.massAction.clientIds : null
    },
    massPropertyIds() {
      return this.massAction ? this.massAction.propertyIds : null
    },
    weekdays() {
      return WEEKDAYS.map((name, id) => ({ name, id }))
    },
    isNotCrruleEndtypeDate() {
      return this.crrule.endtype !== "date"
    },
    isNotCrruleEndtypeDays() {
      return this.crrule.endtype !== "days"
    },
    crruleByMonthOptions() {
      const date = moment(this.params.startsAt)
      const week = weekOfMonth(date)
      const counter = week === -1 ? "letzten" : week
      return [
        [`${this.$t("panes.taskForm.monthly", { date: date.date() || "" })}.`, "date"],
        [`${this.$t("panes.taskForm.monthly", { date: counter || "" })}. ${date.format("dddd") || ""}`, "monthweek"],
      ].map(([name, id]) => ({ name, id }))
    },
    crruleFreqIsWeekly() {
      return this.crrule.freq === 2
    },
    crruleFreqIsMonthly() {
      return this.crrule.freq === 1
    },
    isNote() {
      return this.type == "note" || this.type == "inquiry" || this.type == "reason"
    },
    isReminder() {
      return this.type == "todo"
    },
    isEvent() {
      return this.type == "event"
    },
    isDecision() {
      return this.type == "decision"
    },
    categoryCollectionName() {
      if (this.isReminder) return "todoCategories"
      if (this.isEvent) return "eventCategories"
      if (this.type === "decision") return "decisionCategories"
      if (this.type === "letter") return "letterCategories"
      return "noteCategories"
    },
    noteType() {
      return this.$db.shopData[this.categoryCollectionName].find(o => o.id == this.params.categoryId)
    },
    noteTypeTitle() {
      return this.noteType && this.noteType.name
    },
    noteTypeBody() {
      return this.noteType && this.noteType.body
    },
    titleHasVariables() {
      return this.params.title && this.params.title.match(/\{\{.+\}\}/)
    },
    locationHasVariables() {
      return this.params.location && this.params.location.match(/\{\{.+\}\}/)
    },
    priorityOptions() {
      return [{ id: "high" }, { id: "normal" }, { id: "low" }].map(p => ({
        ...p,
        name: this.$t(`panes.taskForm.priority.${p.id}`),
      }))
    },
    slotSteps() {
      return [5, 10, 15, 20, 25, 30, 35, 40, 45, 60, 90, 120].map(n => ({
        id: n,
        name: this.$t("panes.taskForm.minutes", { time: n }),
      }))
    },
    remindOptions() {
      return [
        { id: null },
        { id: 10 },
        { id: 30 },
        { id: 45 },
        { id: 90 },
        { id: 60 },
        { id: 120 },
        { id: 360 },
        { id: 720 },
        { id: 1440 },
        { id: 2880 },
        { id: 4320 },
      ].map(r => ({
        ...r,
        name: this.$t(`panes.taskForm.remindOptions.${r.id}`),
      }))
    },
    eventFormat() {
      return this.params.allDay ? "dd.MM.yyyy" : "dd.MM.yyyy HH:mm"
    },
    rruleFreqOptions() {
      return [{ id: 3 }, { id: 2 }, { id: 1 }, { id: 0 }].map(f => ({
        ...f,
        name: this.$t(`panes.taskForm.frequencyOptions.${f.id}`),
      }))
    },
    selectedProjects() {
      return this.params.projectIds?.length
        ? this.$db.shopData.projects.filter(p => this.params.projectIds.includes(p.id))
        : []
    },
    locations() {
      return [
        ...new Set(
          [
            ...this.$db.shopData.autocompleteEventLocations,
            ...this.selectedClients.map(o => o.address),
            ...this.selectedClients.map(o => o.office_address),
            ...this.selectedProperties.map(o => o.short_address),
            ...this.selectedProjects.map(o => o.address),
          ].filter(Boolean)
        ),
      ]
    },
    within48h() {
      const today = new Date()
      return !moment(this.params.createdAt || today)
        .add(2, "d")
        .isBefore(this.params.endsAt)
    },
    timeAvailabilityUsername() {
      const broker = this.$db.shopData.brokers.find(b => b.id == this.params.brokerId)
      const department = this.$db.shopData.departments.find(d => d.id == this.params.departmentId)
      return broker ? broker.name : department ? department.name : null
    },
    selectedPipelineId() {
      return this.noteType?.taskPipelineId
    },
  },
  async mounted() {
    if (this.taskId) {
      await this.fetchResource()
    } else {
      await this.buildResource()
    }
  },
}
</script>
<style lang="scss">
.task {
  .el-input__inner {
    height: 30px !important;
    font-size: 13px !important;
    padding-left: 12px;
  }
  .box-row {
    margin-bottom: 1rem !important;
  }
}

.rights-btn {
  display: block;
  font-size: 14px;
  line-height: 1;
  color: #222;
  border-radius: 3px;
  padding: 7px 8px;
  -webkit-transition: color 100ms linear;
  transition: color 100ms linear;
}
</style>

<style lang="postcss">
.datepicker--taken .el-input__inner {
  @apply text-red-600 border-red-600;
}

.datepicker--taken .el-input__inner:hover,
.datepicker--taken .el-input__inner:focus {
  @apply border-red-800;
}

.datepicker--taken .el-input__prefix {
  @apply text-red-700;
}

.datepicker--available .el-input__inner {
  @apply text-green-600 border-green-600;
}

.datepicker--available .el-input__inner:hover,
.datepicker--available .el-input__inner:focus {
  @apply border-green-800;
}

.datepicker--available .el-input__prefix {
  @apply text-green-700;
}
</style>
