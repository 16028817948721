export default {
  "/dashboard": "Dashboard",
  "/mailbox/messages": "Mailbox",
  "/mailbox/messages/sent": "Gesendet",
  "/mailbox/messages/favorites": "Favoriten",
  "/mailbox/messages/spam": "Spam",
  "/mailbox/messages/drafts": "Entwürfe",
  "/mailbox/messages/me": "Mit mir geteilt",
  "/mailbox/messages/archived": "Archiviert",
  "/mailbox/messages/trash": "Gelöscht",
  "/contacts/clients": "Kontakte",
  "/calendar": "Kalender",
  "/opportunities": "Deals",
  "/portfolio/projects": "Projekte",
  "/external-reports": "Reports",
  "/marketing/mailings": "Mailings",
  "/tasks/todos": "Aufgaben",
  "/contacts/feed": "Aktivitäten",
  "/contacts/followups": "Followups",
  "/contacts/duplicates": "Duplikate",
  "/contacts/trash": "Papierkorb",
  "/contacts/logs": "Logs",
  "/portfolio/properties": "Objekte",
  "/export/cart": "Export",
  "/admin/connections": "Portale",
  "/admin/newsletter": "Newsletter",
  "/admin/messagebird": "Messagebird",
  "/admin/mailgun": "Mailgun",
  "/admin/api_keys": "API-Schlüssel",
  "/admin/brokers": "Nutzer",
  "/admin/teams": "Mandanten",
  "/admin/departments": "Teams",
  "/admin/settings/shop": "Unternehmen",
  "/admin/automations": "Automatisierungen",
  "/admin/rights": "Rechte",
  "/admin/settings/tasks": "Aktivitätstypen",
  "/admin/shop/valuations": "Bewertungen",
  "/admin/shop/valuations/sprengnetter": "Sprengnetter-Account",
  "/admin/shop/propnow": "PropNow-Account",
  "/admin/locations": "Geolagen",
  "/admin/letter_templates": "Briefvorlagen",
  "/admin/custom_fields": "Custom-Felder",
  "/admin/settings/gdpr": "DSGVO",
  "/admin/settings/lead_inquiries": "Eigentümer-Anfragen",
  "/admin/settings/messages": "E-Mail-Einstellungen",
  "/admin/settings/contacts": "Kontakt-Einstellungen",
  "/admin/shop/micro_sites": "Landing Pages",
  "/admin/shop/lead_score": "Lead-Score",
  "/admin/shop/immo_valuation": "Leadfisher",
  "/admin/settings/media": "Medien-Einstellungen",
  "/admin/super_groups": "Merkmale",
  "/admin/settings/properties": "Objekt-Einstellungen",
  "/admin/settings/property_report": "Objekt-Report",
  "/admin/shop/pdf_exposees": "PDF-Exposés",
  "/admin/settings/deals": "Deal-Einstellungen",
  "/admin/settings/deals_view": "Deal-Ansicht",
  "/admin/settings/pipelines": "Deal-Pipelines",
  "/admin/settings/task_pipelines": "Aufgaben-Pipelines",
  "/admin/settings/reports": "Reports",
  "/admin/settings/saved_queries": "Suchprofil-Einstellungen",
  "/admin/settings": "Systemeinstellungen",
  "/admin/shopwindows": "Schaufenster-Screens",
  "/admin/settings/events": "Termin-Einstellungen",
  "/admin/booking_calendars": "Termin-Planer",
  "/admin/snippet_categories": "Textbausteine",
}
