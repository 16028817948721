<template>
  <tooltip v-if="fullBrokers?.length" :content="`${$t('brokers.totalUsers', { users: fullBrokers.length })}`">
    <div class="flex ml-2">
      <avatar
        v-for="(broker, idx) in fullBrokers.slice(0, maxBrokers)"
        :key="broker.id"
        :broker="broker"
        :class="{ '-ml-2': idx > 0 }"
        :size="size"
      />
      <avatar v-if="fullBrokers.length > count" :broker="fakeBroker" class="-ml-2" :size="size" />
    </div>
  </tooltip>
  <div v-else class="flex ml-2">
    <avatar
      v-for="(broker, idx) in brokers"
      :key="broker.id"
      :broker="broker"
      :class="{ '-ml-2': idx > 0 }"
      :size="size"
    />
    <avatar v-if="brokerIds.length > maxBrokers" :broker="fakeBroker" class="-ml-2" :size="size" />
  </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from "vue"
import type { Broker } from "@/interfaces/db"
import useCore from "@/plugins/use-core"

export type BrokerAvatarsProps = {
  brokerIds: number[]
  fullBrokers?: Broker[]
  count?: number
  size?: string
}

const { brokerIds, fullBrokers = [], count = 11, size } = defineProps<BrokerAvatarsProps>()
const { db } = useCore()

const maxBrokers: ComputedRef<number> = computed(() => {
  return (fullBrokers?.length ? fullBrokers.length : brokerIds.length) === count ? count : count - 1
})

const brokers: ComputedRef<Broker[]> = computed(() => {
  let tempBrokers: Broker[] = []
  if (!fullBrokers?.length) {
    tempBrokers = db.shopData.brokers
      // filter brokers by brokerIds
      .filter(b => brokerIds.includes(b.id))
      // return brokers upto length of maxBrokers
      .slice(0, maxBrokers.value)
  }
  return tempBrokers
})

const fakeBroker: ComputedRef<Partial<Broker>> = computed(() => {
  return {
    name: `+ ${(fullBrokers?.length || brokerIds.length) - maxBrokers.value}`,
  }
})
</script>
