<template>
  <div class="comment-wrap" :class="{ 'comment-form--expanded': commentFormExpanded }">
    <div class="flex justify-end mb-3 -mt-1">
      <a href="#" @click="changeOrder" class="text-gray-600 text-sm">
        <fa-icon name="sort-alt" />
        {{
          $t("commentsApp.sort", {
            action: $t(`commentsApp.${isCommentOrderAsc ? "newest" : "oldest"}`),
          })
        }}
      </a>
      <a href="#" @click="showActivityLogs" class="ml-3 text-gray-600 text-sm">
        {{ $t("commentsApp.logs", { action: $t(`commentsApp.${hideActivityLogs ? "show" : "hide"}`) }) }}
      </a>
    </div>
    <div v-if="!loading" class="comment-list" @click="commentFormExpanded = false">
      <slot v-if="isCommentOrderAsc && activityLogsVisible" />
      <div v-for="item in items" :key="(item.isLog ? 'log-' : 'comment-') + item.id">
        <div v-if="item.isLog" class="activityLog-item">
          <p>
            <strong>{{ (item.broker && item.broker.name) || $t("commentsApp.sync") }}</strong>
            {{ item.prettyText }}
          </p>
          <tooltip class="activityLog-date" :content="$customFilters.datetime(item.created_at)" placement="left">
            <span>{{ $customFilters.calendar(item.created_at) }}</span>
          </tooltip>
        </div>
        <comment-item v-else :item="item" @delete="removeComment" />
      </div>
      <slot v-if="!isCommentOrderAsc && activityLogsVisible" />
    </div>

    <footer class="comment-footer" @click.stop="commentFormExpanded = true">
      <div>
        <div
          v-if="resourceType == 'Message' && lastComment"
          v-show="lastCommentVisible"
          class="lastComment-wrapper"
          @click.stop="scrollDown"
        >
          <comment-item :item="lastComment" style="pointer-events: none" />
        </div>
        <div class="comment-item comment-form">
          <div class="comment-itemAvatar">
            <avatar :broker="currentBroker" size="32px" />
          </div>
          <div class="comment-itemContent">
            <nice-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4 }"
              :placeholder="$t('commentsApp.placeholder')"
              @keydown.enter="handleEnter"
              :class="{ disabled: posting }"
              v-model="body"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex items-center">
          <div style="padding: 0 24px"><!-- For styling --></div>
          <tooltip :content="$t('commentsApp.collaborators.info')" class="mr-2">
            <span class="text-sm text-gray-600 cursor-default">{{ $t("commentsApp.collaborators.title") }}</span>
          </tooltip>
          <broker-avatars :brokerIds="collaborators" />
          <tooltip :content="$t('commentsApp.collaborators.add')">
            <button class="py-1 px-2 hover:bg-gray-100 rounded-md" @click="modalVisible = true">
              <fa-icon name="plus" class="text-sm" />
            </button>
          </tooltip>
        </div>
        <div>
          <tooltip v-if="currentBrokerIsCollaborator" :content="$t('commentsApp.collaborators.leaveTooltip')">
            <button
              @click="removeCurrentBrokerFromWatchers"
              class="p-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            >
              <fa-icon name="bell-slash" />
              {{ $t("commentsApp.collaborators.leave") }}
            </button>
          </tooltip>
          <tooltip v-else :content="$t('commentsApp.collaborators.joinTooltip')">
            <button @click="addCurrentBrokerToWatchers" class="p-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md">
              <fa-icon name="bell" />
              {{ $t("commentsApp.collaborators.join") }}
            </button>
          </tooltip>
        </div>
      </div>
    </footer>
    <form-dialog
      width="360px"
      footer-type="bigButton"
      :visible="modalVisible"
      @close="modalVisible = false"
      :title="$t('commentsApp.collaborators.add')"
      append-to-body
      @submit="updateWatchers(totalCollaborators, true), (modalVisible = false)"
    >
      <db-select fixed-position v-model="totalCollaborators" multiple collection="activeBrokers" class="-my-2" />
    </form-dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, nextTick, computed, onMounted, onUnmounted } from "vue"
import { formatLog } from "@/config/logs"
import useCore from "@/plugins/use-core"
import CommentItem from "./CommentItem.vue"

const { db, axios, api, root } = useCore()

type Comment = {
  id: number
  broker_id: number
  body: string
  created_at: string
  updated_at: string
}
type CommentProps = {
  resourceType: string
  resourceId: number
  watchers: number[]
}
const { resourceType, resourceId, watchers } = defineProps<CommentProps>()

// state variables
const comments = ref<Comment[]>([])
const collaborators = ref(watchers || [])
const totalCollaborators = ref(watchers || [])
const loading = ref<boolean>(true)
const posting = ref<boolean>(false)
const modalVisible = ref<boolean>(false)
const body = ref<string>("")
const commentFormExpanded = ref<boolean>(false)
const lastCommentVisible = ref<boolean>(true)
const logs = ref({
  data: [] as any[],
  loading: false,
})
const hideActivityLogs = ref(db.broker?.hideActivityLogs || false)
const commentsOrder = ref<"asc" | "desc">(db.broker?.commentsOrder || "asc")

// methods
const fetchData = () => {
  loading.value = true

  axios
    .get(`/api/v1/comments?commentable_id=${resourceId}&commentable_type=${resourceType}`)
    .then(({ data }) => {
      comments.value = data.data
      return fetchLogs(resourceId, resourceType)
    })
    .then(() => {
      loading.value = false
      nextTick(() => {
        $("#message-detail-container").trigger("scroll")
      })
    })
}

const fetchLogs = (resourceId: number, resourceType: string) => {
  logs.value.loading = true
  return axios.get(`/api/v1/activity_logs?loggable_id=${resourceId}&loggable_type=${resourceType}`).then(({ data }) => {
    logs.value.data = data.data.map(b => formatLog(b, brokers.value))
    logs.value.loading = false
  })
}

const handleEnter = e => {
  if (posting.value || e.shiftKey) return

  e.preventDefault()
  addItem()
}

const addItem = () => {
  if (!body.value || posting.value) return

  posting.value = true

  axios
    .post("/api/v1/comments", {
      comment: {
        body: body.value,
        commentable_id: resourceId,
        commentable_type: resourceType,
      },
    })
    .then(() => {
      body.value = ""
      posting.value = false
      if (commentsOrder.value === "asc") {
        const element = $(".comment-wrap")?.closest(".app-panel-body")?.get(0)
        if (element) element.scrollTop = 9999999
      }
    })
    .catch(err => {
      posting.value = false
      axios.handleError(err)
    })
}

const removeComment = (comment: Comment) => {
  comments.value = comments.value.filter(o => o.id !== comment.id)
}
const subscribe = () => {
  root?.$pusher?.off(`comment:${resourceType}:${resourceId}:created`)
  root?.$pusher?.off(`comment:${resourceType}:${resourceId}:destroyed`)

  root?.$pusher?.on(`comment:${resourceType}:${resourceId}:created`, comment => {
    comments.value = comments.value.filter(o => o.id !== comment.id).concat([comment])
  })

  root?.$pusher?.on(`comment:${resourceType}:${resourceId}:destroyed`, data => {
    comments.value = comments.value.filter(o => o.id !== data.comment_id)
  })
}

const scrollDown = () => {
  const container = document.getElementById("message-detail-container")

  if (lastCommentEl?.value && container) container.scrollTo({ top: lastCommentEl.value.offsetTop })
}
const showActivityLogs = () => {
  hideActivityLogs.value = !hideActivityLogs.value
  db.updateBroker({ hideActivityLogs: hideActivityLogs.value })
}
const changeOrder = () => {
  commentsOrder.value = commentsOrder.value === "asc" ? "desc" : "asc"
  db.updateBroker({ commentsOrder: commentsOrder.value })
}
const updateWatchers = (watchers: number[], all?: boolean) => {
  if (resourceType === "Task") {
    api
      .mutation("updateTask", {
        id: resourceId,
        payload: {
          watcherBrokerIds: watchers,
        },
      })
      .then(() => {
        totalCollaborators.value = watchers
        collaborators.value = watchers
      })
  }
  if (resourceType === "Message") {
    axios
      .put(`/mailbox/messages/${resourceId}`, {
        message: {
          watcher_broker_ids: watchers,
        },
      })
      .then(() => {
        totalCollaborators.value = watchers
        collaborators.value = watchers
      })
  }
  if (all) collaborators.value = totalCollaborators.value
}
const removeCurrentBrokerFromWatchers = () => {
  updateWatchers(collaborators.value.filter(b => b !== currentBroker.value.id))
}
const addCurrentBrokerToWatchers = () => {
  updateWatchers(collaborators.value.concat(currentBroker.value.id))
}

// computed variables
const currentBroker = computed(() => db.broker)
const currentBrokerIsCollaborator = computed(() => collaborators.value.includes(currentBroker.value.id))
const isCommentOrderAsc = computed<boolean>(() => commentsOrder.value === "asc")
const activityLogsVisible = computed(() => {
  return !hideActivityLogs.value
})
const hydratedItems = computed(() => {
  let items = comments.value.map(c => ({ ...c, isComment: true }))
  if (activityLogsVisible.value) items = items.concat(logs.value.data)

  return items.map(o => ({ ...o, broker: brokers.value.find(b => b.id === o.broker_id) }))
})
const items = computed(() => {
  return _.orderBy(hydratedItems.value, "created_at", commentsOrder.value)
})
const lastComment = computed(() => {
  return comments.value[comments.value.length - 1]
})
const lastCommentEl = computed(() => {
  return lastComment.value ? $(".comment-list .comment-item")?.last()?.get?.(0) : null
})
const brokers = computed(() => {
  return db.shopData.brokers
})

// lifecycle hooks

let documentClickListener
let messageDetailContainerListener
onMounted(() => {
  fetchData()
  subscribe()

  documentClickListener = document.addEventListener("click", () => {
    commentFormExpanded.value = false
    return true
  })

  messageDetailContainerListener = $("#message-detail-container").on(
    "scroll",
    _.throttle(e => {
      if (!lastCommentEl?.value) return

      const threshold = lastCommentEl.value.offsetTop - e.target.offsetHeight + lastCommentEl.value.offsetHeight + 60

      lastCommentVisible.value = e.target.scrollTop < threshold
    }, 16)
  )
})

onUnmounted(() => {
  document.removeEventListener("click", documentClickListener)
  $("#message-detail-container").off("scroll", messageDetailContainerListener)
})
</script>

<style>
.comment-wrap {
  padding: 1rem 20px 1.5rem;
  margin-bottom: 60px;
  border-top: 1px solid #eeeeee;
  background: #fafafa;
}

.comment-item {
  display: flex;
  padding: 8px 0;
}

.comment-itemAvatar {
  width: 32px;
  margin-right: 15px;
}

.comment-itemAvatar .avatarItem {
  width: 32px;
  height: 32px;
}
.comment-item .avatarItem {
  margin-top: 18px;
}

.comment-itemContent {
  flex: 1;
}

.comment-itemBody {
  white-space: pre-line;
  background: #e9e9e9;
  border-radius: 15px;
  padding: 6px 10px;
  max-width: 450px;
  float: left;
}

.comment-itemAuthor {
  font-weight: 500;
  font-size: 0.85rem;
  margin-left: 10px;
  margin-bottom: 0.25rem;
}

.comment-itemMeta {
  margin-left: 1rem;
  color: #888888;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  visibility: hidden;
}

.comment-item:hover .comment-itemMeta {
  visibility: visible;
}

.comment-formTrigger {
  margin-left: 1rem;
  display: none;
}

.comment-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 4px 20px;
  border-top: 1px solid #e3e3e3;
  z-index: 3;
}

.comment-form .avatarItem {
  margin-top: 0;
}

.comment-form textarea {
  /* padding-top: 8px; */
  resize: none;
}

/* .comment-wrap:not(.comment-form--expanded) .comment-form textarea {
  height: 36px;
}

.comment-wrap.comment-form--expanded {
  margin-bottom: 177px;
}

.comment-wrap.comment-form--expanded .comment-formTrigger {
  display: block;
} */

.activityLog-item {
  display: flex;
  justify-content: space-between;
  padding: 2px 0 2px 47px;
  color: #777777;
}

.activityLog-date,
.comment-date {
  color: #999999;
  font-size: 0.9rem;
  align-self: center;
}

.activityLog-item p {
  font-size: 0.9rem;
}

.activityLog-item p:first-child {
  flex: 1;
  text-align: center;
}

.activityLog-item p:not(:first-child) {
  margin-left: 1rem;
}

.activityLog-item p strong {
  font-weight: 400;
}

.lastComment-wrapper {
  cursor: pointer;
}

.lastComment-wrapper .comment-itemBody {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 4px;
}
</style>
