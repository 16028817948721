<template>
  <dropdown :disabled="selected.length <= 0">
    <template #reference="{ referenceClass }">
      <a href="#" @click.prevent :class="['tableActions-action', referenceClass]">
        {{ $t(title) }} ({{ selected.length }})
        <fa-icon name="angle-down" class="ml-1" />
      </a>
    </template>
    <dropdown-section>
      <dropdown-item
        :title="$t('clients.actionsDropdown.massMail')"
        @click="quickview('mail')"
        v-if="$db.broker.canMassMail"
      />
      <dropdown-item :title="$t('clients.actionsDropdown.letter')" @click="quickview('letter')" />
      <dropdown-item :title="$t('clients.actionsDropdown.note')" @click="quickview('note')" />
      <dropdown-item :title="$t('clients.actionsDropdown.todo')" @click="quickview('todo')" />
      <dropdown-item :title="$t('clients.actionsDropdown.reason')" @click="quickview('reason')" />
      <dropdown-item :title="$t('clients.actionsDropdown.deal')" @click="dealFormVisible = true" />
    </dropdown-section>
    <worklist-actions :selected="selected" @clear-selected="$emit('update:selected', [])" entity="for_contacts" />
    <report-actions :selected="selected" :sortBy="computedSortBy" :order="computedOrder" type="contact" />
    <dropdown-section v-if="$db.broker.canSyncContacts">
      <dropdown-item :title="$t('clients.actionsDropdown.addAddressbook')" @click="addToAddressBook" />
      <dropdown-item :title="$t('clients.actionsDropdown.removeAddressbook')" @click="removeFromAddressBook" />
    </dropdown-section>
    <dropdown-section>
      <dropdown-item :title="$t('clients.actionsDropdown.addQuery')" @click="openMassSavedQuery" />
      <dropdown-item :title="$t('clients.actionsDropdown.edit')" v-if="$db.broker.canBulkEdit" @click="openMassEdit" />
      <dropdown-item
        v-if="$db.broker.canDeleteClients"
        :title="$t('clients.actionsDropdown.delete')"
        @click="massDeleteDialogVisible = true"
        class="text-danger"
      />
      <number-confirmation-dialog
        :model-value="massDeleteDialogVisible"
        :correctValue="selected.length"
        :title="$t('clients.actionsDropdown.delete')"
        :description="$t('clients.actionsDropdown.deleteConfirm', { number: selected.length })"
        :placeholder="$t('clients.actionsDropdown.deletePh')"
        :request="deleteClients"
        @submitted="clientsDeleted"
        @update:modelValue="massDeleteDialogVisible = $event"
      >
        <div class="mt-3">
          <nice-checkbox v-model="deleteWithActivities">
            <span class="text-sm">{{ $t("clients.actionsDropdown.deleteActivities") }}</span>
          </nice-checkbox>
        </div>
      </number-confirmation-dialog>
      <mass-edit-dialog
        :title="$t('clients.actionsDropdown.editFormTitle')"
        resourceType="client"
        :fields="editableFields"
        v-model:visible="massEditDialogVisible"
        :selected="selected"
        @submitted="clientsEdited"
      />
      <deal-form
        v-model:visible="dealFormVisible"
        :client-ids="selected"
        :cp="{
          id: null,
          propertyId: propertyId,
          brokerId: $db.broker.id,
          dealPipelineId: $db.shopData.dealPipelines.map(o => o.id)[0],
        }"
      ></deal-form>
    </dropdown-section>
  </dropdown>
</template>

<script>
import WorklistActions from "./WorklistActions"
import NumberConfirmationDialog from "../NumberConfirmationDialog"
import MassEditDialog from "../MassEditDialog"
import eventBus from "@/config/event-bus"
import { getEditableFields } from "@/config/client/editable-fields"
import DealForm from "@/components/deals/Form.vue"
import ReportActions from "@/components/ReportActions.vue"
import { useI18n } from "vue-i18n"
import useShop from "@/plugins/use-shop"
import { snakeCase } from "@/utils/with-case"

const UNEDITABLE_FIELDS = [
  "partnerships",
  "associates",
  "childrenName",
  "phoneNumbers",
  "ownerships",
  "age",
  "officeAddress",
  "messageSalutation",
  "homeAddress",
  "photoUrl",
  "emails",
  "name",
]

export default {
  components: {
    NumberConfirmationDialog,
    MassEditDialog,
    WorklistActions,
    DealForm,
    ReportActions,
  },
  props: {
    selected: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
    },
    order: {
      type: String,
    },
    title: {
      type: String,
      default: "clients.actionsDropdown.title",
    },
    propertyId: {
      type: Number,
    },
  },
  data() {
    return {
      addressBookSyncing: false,
      massDeleteDialogVisible: false,
      massEditDialogVisible: false,
      deleteWithActivities: false,
      dealFormVisible: false,
    }
  },

  watch: {
    selected: {
      handler() {
        this.quickviewUpdate()
      },
    },
  },

  computed: {
    computedOrder() {
      return this.order || this.$db.broker.clientSearchOrder || "desc"
    },
    computedSortBy() {
      return this.sortBy || this.$db.broker.clientSearchSortBy || "last_contact_at"
    },
    params() {
      return $.param({
        mass_client_ids: this.selected.join(","),
        property_id: this.propertyId,
      })
    },
    protectedFields() {
      return this.$db.shopData.clientDetailViewGroups.flatMap(group => {
        const { readBrokerIds = [], readDepartmentIds = [] } = group
        const groupHasRights = readBrokerIds.length || readDepartmentIds.length

        const canSeeGroup = readBrokerIds.includes(this.$db.broker.id)
        const brokerDepartmentIds = this.$db.broker.departmentIds
        const departmentCanSeeGroup = _.intersection(readDepartmentIds, brokerDepartmentIds).length

        const hasAccessToGroup = !groupHasRights || canSeeGroup || departmentCanSeeGroup

        return group.detailViewFields
          .filter(f => {
            if (!hasAccessToGroup) return true

            const fieldHasRights =
              f.readBrokerIds.length ||
              f.writeBrokerIds.length ||
              f.readDepartmentIds.length ||
              f.writeDepartmentIds.length

            const canSee = f.readBrokerIds.includes(this.$db.broker.id)
            const departmentCanSee = _.intersection(f.readDepartmentIds, brokerDepartmentIds).length

            const noReadAccessToField = fieldHasRights && !canSee && !departmentCanSee
            if (noReadAccessToField) return true

            const canWrite = f.writeBrokerIds.includes(this.$db.broker.id)
            const departmentCanWrite = _.intersection(f.writeDepartmentIds, brokerDepartmentIds).length
            const writeAccessToField = !fieldHasRights || canWrite || departmentCanWrite

            return !writeAccessToField
          })
          .map(f =>
            f.fieldName.startsWith("cf_") ? `partial_custom_fields__${f.fieldName.replace("cf_", "")}` : f.fieldName
          )
      })
    },
    editableFields() {
      return getEditableFields(this.$db, this.$t)
        .map(f => {
          const translated = {
            ...f,
            label:
              this.$db.getItemFieldTitle(f.id, "client") || f.isCustomField
                ? f.label || this.$t(`clients.formFields.${snakeCase(f.filterName || f.id)}`)
                : this.$t(`clients.formFields.${snakeCase(f.filterName || f.id)}`),
            disabled:
              this.protectedFields.includes(f.id) ||
              (!["broker_id", "second_broker_id", "client_status_id"].includes(f.id) &&
                !this.$db.broker.canEditClients) ||
              (["broker_id", "second_broker_id"].includes(f.id) &&
                !this.$db.broker.canChangeBroker &&
                !this.$db.broker.canEditClients) ||
              (f.id === "client_status_id" &&
                !this.$db.broker.canChangeClientStatus &&
                !this.$db.broker.canEditClients),
          }
          if (!!f.options && Array.isArray(f.options)) {
            translated.options = f.options.map(o =>
              o.translate ? { id: o.id, name: this.$t(`clients.formFieldOptions.${o.name}`) } : o
            )
          }
          return translated
        })
        .filter(f => !UNEDITABLE_FIELDS.includes(f.id))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  },

  methods: {
    quickview(type) {
      eventBus.$emit("quick-view", {
        type,
        mode: "edit",
        params: {
          massAction: {
            clientIds: this.selected,
          },
          source: this.propertyId ? { propertyIds: [this.propertyId] } : {},
        },
      })
    },

    quickviewUpdate() {
      eventBus.$emit("quick-view-update", {
        // type: type,
        // mode: "edit",
        params: {
          massAction: {
            clientIds: this.selected,
            propertyIds: [this.propertyId].filter(Boolean),
          },
        },
      })
    },

    openMassEdit() {
      this.massEditDialogVisible = true
    },
    openMassSavedQuery() {
      eventBus.$emit("quick-view", {
        type: "query",
        mode: "edit",
        params: {
          massAction: {
            clientIds: this.selected,
          },
        },
      })
    },

    addToAddressBook() {
      if (this.addressBookSyncing) {
        return
      }

      this.addressBookSyncing = true
      this.$axios
        .post("/contacts/syncs/add", {
          client_ids: this.selected,
        })
        .then(() => {
          App.flashy(this.$t("clients.actionsDropdown.addressbookAddSuccess"))
          this.$emit("update:selected", [])
        })
        .catch(this.$axios.handleError)
        .finally(() => {
          this.addressBookSyncing = false
        })
    },
    removeFromAddressBook() {
      if (this.addressBookSyncing) return

      this.addressBookSyncing = true
      this.$axios
        .post("/contacts/syncs/remove", {
          client_ids: this.selected,
        })
        .then(_ => {
          this.addressBookSyncing = false
          App.flashy(this.$t("clients.actionsDropdown.addressbookRemoveSuccess"))
          this.$emit("update:selected", [])
        })
        .catch(err => {
          this.addressBookSyncing = false
          this.$axios.handleError(err)
        })
    },

    deleteClients() {
      return this.$axios.post("/api/v1/contacts/delete", {
        client_ids: this.selected,
        delete_with_activities: this.deleteWithActivities,
      })
    },
    clientsEdited() {
      this.massEditDialogVisible = false
      this.$emit("update:selected", [])
      App.flashy(this.$t("clients.actionsDropdown.editSuccess"))
      if (this.$parent.fetchData) this.$parent.fetchData()
    },
    clientsDeleted() {
      this.massDeleteDialogVisible = false
      this.$emit("update:selected", [])
      App.flashy(this.$t("clients.actionsDropdown.deleteSuccess"))
      if (this.$parent.fetchData) this.$parent.fetchData()
    },
  },
}
</script>
