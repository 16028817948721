<template>
  <tooltip :content="tooltipText" :disabled="$slots.tooltipText ? false : !tooltipText">
    <template v-slot:content>
      <slot name="tooltipText"></slot>
    </template>
    <span @click="clicked" class="bubble" :style="{ 'background-color': background }" :class="{ pointer: url }">
      <slot></slot>
    </span>
  </tooltip>
</template>

<script setup lang="ts">
import { computed } from "vue"
const emit = defineEmits(["click"])

export type BubbleType = "light" | "success" | "warning" | "danger" | "info" | "dark"
export type BubbleProps = {
  color?: string
  tooltipText?: string
  type?: BubbleType
  url?: string
}
const { color, tooltipText, type, url } = defineProps<BubbleProps>()

const COLORS: Record<BubbleType, string> = {
  light: "#999999",
  success: "#22dd33",
  warning: "#cc7700",
  danger: "#ff0000",
  info: "#3388ff",
  dark: "#999999",
}

function clicked() {
  emit("click")
  if (url) {
    Turbolinks.visit(url)
  }
}
const background = computed(() => `${color || (type && COLORS[type]) || "#555555"}44`)
</script>

<style scoped>
.bubble {
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: #222222;
  font-size: 0.85rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
